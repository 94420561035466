import type { UserSubscriptionDto } from '~/dto';

export const useSubsrbStore = defineStore('subsrb', () => {
  const subscrb = ref<UserSubscriptionDto | null>(null);

  const patchSubscrb = (newSubsrb: UserSubscriptionDto | null) => {
    if (newSubsrb && newSubsrb.plan.features && 'mobileAppAccess' in newSubsrb.plan.features) {
      delete newSubsrb.plan.features.mobileAppAccess;
    }
    subscrb.value = newSubsrb;
  };

  return {
    subscrb,
    patchSubscrb,
  }
});
