
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as change_45passwordlnF89pKfG4Meta } from "/app/pages/account/change-password.vue?macro=true";
import { default as indexQWKFaMHH2OMeta } from "/app/pages/account/index.vue?macro=true";
import { default as change_45passwordX8ivx1vU2lMeta } from "/app/pages/auth/change-password.vue?macro=true";
import { default as confirm_45accountvbPahADDW0Meta } from "/app/pages/auth/confirm-account.vue?macro=true";
import { default as reset_45password5ABZjlJW61Meta } from "/app/pages/auth/reset-password.vue?macro=true";
import { default as signinFK2lTWFqnvMeta } from "/app/pages/auth/signin.vue?macro=true";
import { default as signupHJ1GDnyyMkMeta } from "/app/pages/auth/signup.vue?macro=true";
import { default as _91_id_93SzmYjArBjaMeta } from "/app/pages/blog/[_id].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as privacyvSm3wr4bxjMeta } from "/app/pages/documents/privacy.vue?macro=true";
import { default as termsdb5PyA4gx0Meta } from "/app/pages/documents/terms.vue?macro=true";
import { default as ua8DX7n8W6lnMeta } from "/app/pages/documents/ua.vue?macro=true";
import { default as documentsAtnLAchromMeta } from "/app/pages/documents.vue?macro=true";
import { default as downloadsynMhguGMjYMeta } from "/app/pages/downloads.vue?macro=true";
import { default as _91id_93MRJgxkWB8iMeta } from "/app/pages/email/get/[id].vue?macro=true";
import { default as _91id_93VoQbvPFVxgMeta } from "/app/pages/email/verify/[id].vue?macro=true";
import { default as faqIMkmXQpKKaMeta } from "/app/pages/faq.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91_id_93zX6iU92mf7Meta } from "/app/pages/partners/[_id].vue?macro=true";
import { default as indexLG3Wtz8U6fMeta } from "/app/pages/partners/index.vue?macro=true";
import { default as successUvCmUCQewKMeta } from "/app/pages/payment/success.vue?macro=true";
import { default as pricesC8xY6SsZYuMeta } from "/app/pages/prices.vue?macro=true";
import { default as referalCBFjzhrveyMeta } from "/app/pages/referal.vue?macro=true";
export default [
  {
    name: "account-change-password___en",
    path: "/en/account/change-password",
    component: () => import("/app/pages/account/change-password.vue")
  },
  {
    name: "account-change-password___ua",
    path: "/ua/account/change-password",
    component: () => import("/app/pages/account/change-password.vue")
  },
  {
    name: "account-change-password___ru",
    path: "/ru/account/change-password",
    component: () => import("/app/pages/account/change-password.vue")
  },
  {
    name: "account___en",
    path: "/en/account",
    meta: indexQWKFaMHH2OMeta || {},
    component: () => import("/app/pages/account/index.vue")
  },
  {
    name: "account___ua",
    path: "/ua/account",
    meta: indexQWKFaMHH2OMeta || {},
    component: () => import("/app/pages/account/index.vue")
  },
  {
    name: "account___ru",
    path: "/ru/account",
    meta: indexQWKFaMHH2OMeta || {},
    component: () => import("/app/pages/account/index.vue")
  },
  {
    name: "auth-change-password___en",
    path: "/en/auth/change-password",
    component: () => import("/app/pages/auth/change-password.vue")
  },
  {
    name: "auth-change-password___ua",
    path: "/ua/auth/change-password",
    component: () => import("/app/pages/auth/change-password.vue")
  },
  {
    name: "auth-change-password___ru",
    path: "/ru/auth/change-password",
    component: () => import("/app/pages/auth/change-password.vue")
  },
  {
    name: "auth-confirm-account___en",
    path: "/en/auth/confirm-account",
    component: () => import("/app/pages/auth/confirm-account.vue")
  },
  {
    name: "auth-confirm-account___ua",
    path: "/ua/auth/confirm-account",
    component: () => import("/app/pages/auth/confirm-account.vue")
  },
  {
    name: "auth-confirm-account___ru",
    path: "/ru/auth/confirm-account",
    component: () => import("/app/pages/auth/confirm-account.vue")
  },
  {
    name: "auth-reset-password___en",
    path: "/en/auth/reset-password",
    component: () => import("/app/pages/auth/reset-password.vue")
  },
  {
    name: "auth-reset-password___ua",
    path: "/ua/auth/reset-password",
    component: () => import("/app/pages/auth/reset-password.vue")
  },
  {
    name: "auth-reset-password___ru",
    path: "/ru/auth/reset-password",
    component: () => import("/app/pages/auth/reset-password.vue")
  },
  {
    name: "auth-signin___en",
    path: "/en/auth/signin",
    component: () => import("/app/pages/auth/signin.vue")
  },
  {
    name: "auth-signin___ua",
    path: "/ua/auth/signin",
    component: () => import("/app/pages/auth/signin.vue")
  },
  {
    name: "auth-signin___ru",
    path: "/ru/auth/signin",
    component: () => import("/app/pages/auth/signin.vue")
  },
  {
    name: "auth-signup___en",
    path: "/en/auth/signup",
    component: () => import("/app/pages/auth/signup.vue")
  },
  {
    name: "auth-signup___ua",
    path: "/ua/auth/signup",
    component: () => import("/app/pages/auth/signup.vue")
  },
  {
    name: "auth-signup___ru",
    path: "/ru/auth/signup",
    component: () => import("/app/pages/auth/signup.vue")
  },
  {
    name: "blog-_id___en",
    path: "/en/blog/:_id()",
    component: () => import("/app/pages/blog/[_id].vue")
  },
  {
    name: "blog-_id___ua",
    path: "/ua/blog/:_id()",
    component: () => import("/app/pages/blog/[_id].vue")
  },
  {
    name: "blog-_id___ru",
    path: "/ru/blog/:_id()",
    component: () => import("/app/pages/blog/[_id].vue")
  },
  {
    name: "blog___en",
    path: "/en/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___ua",
    path: "/ua/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___ru",
    path: "/ru/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "documents___en",
    path: "/en/documents",
    component: () => import("/app/pages/documents.vue"),
    children: [
  {
    name: "documents-privacy___en",
    path: "privacy",
    component: () => import("/app/pages/documents/privacy.vue")
  },
  {
    name: "documents-terms___en",
    path: "terms",
    component: () => import("/app/pages/documents/terms.vue")
  },
  {
    name: "documents-ua___en",
    path: "ua",
    component: () => import("/app/pages/documents/ua.vue")
  }
]
  },
  {
    name: "documents___ua",
    path: "/ua/documents",
    component: () => import("/app/pages/documents.vue"),
    children: [
  {
    name: "documents-privacy___ua",
    path: "privacy",
    component: () => import("/app/pages/documents/privacy.vue")
  },
  {
    name: "documents-terms___ua",
    path: "terms",
    component: () => import("/app/pages/documents/terms.vue")
  },
  {
    name: "documents-ua___ua",
    path: "ua",
    component: () => import("/app/pages/documents/ua.vue")
  }
]
  },
  {
    name: "documents___ru",
    path: "/ru/documents",
    component: () => import("/app/pages/documents.vue"),
    children: [
  {
    name: "documents-privacy___ru",
    path: "privacy",
    component: () => import("/app/pages/documents/privacy.vue")
  },
  {
    name: "documents-terms___ru",
    path: "terms",
    component: () => import("/app/pages/documents/terms.vue")
  },
  {
    name: "documents-ua___ru",
    path: "ua",
    component: () => import("/app/pages/documents/ua.vue")
  }
]
  },
  {
    name: "downloads___en",
    path: "/en/downloads",
    component: () => import("/app/pages/downloads.vue")
  },
  {
    name: "downloads___ua",
    path: "/ua/downloads",
    component: () => import("/app/pages/downloads.vue")
  },
  {
    name: "downloads___ru",
    path: "/ru/downloads",
    component: () => import("/app/pages/downloads.vue")
  },
  {
    name: "email-get-id___en",
    path: "/en/email/get/:id()",
    meta: _91id_93MRJgxkWB8iMeta || {},
    component: () => import("/app/pages/email/get/[id].vue")
  },
  {
    name: "email-get-id___ua",
    path: "/ua/email/get/:id()",
    meta: _91id_93MRJgxkWB8iMeta || {},
    component: () => import("/app/pages/email/get/[id].vue")
  },
  {
    name: "email-get-id___ru",
    path: "/ru/email/get/:id()",
    meta: _91id_93MRJgxkWB8iMeta || {},
    component: () => import("/app/pages/email/get/[id].vue")
  },
  {
    name: "email-verify-id___en",
    path: "/en/email/verify/:id()",
    meta: _91id_93VoQbvPFVxgMeta || {},
    component: () => import("/app/pages/email/verify/[id].vue")
  },
  {
    name: "email-verify-id___ua",
    path: "/ua/email/verify/:id()",
    meta: _91id_93VoQbvPFVxgMeta || {},
    component: () => import("/app/pages/email/verify/[id].vue")
  },
  {
    name: "email-verify-id___ru",
    path: "/ru/email/verify/:id()",
    meta: _91id_93VoQbvPFVxgMeta || {},
    component: () => import("/app/pages/email/verify/[id].vue")
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/app/pages/faq.vue")
  },
  {
    name: "faq___ua",
    path: "/ua/faq",
    component: () => import("/app/pages/faq.vue")
  },
  {
    name: "faq___ru",
    path: "/ru/faq",
    component: () => import("/app/pages/faq.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___ua",
    path: "/ua",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___ru",
    path: "/ru",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "partners-_id___en",
    path: "/en/partners/:_id()",
    component: () => import("/app/pages/partners/[_id].vue")
  },
  {
    name: "partners-_id___ua",
    path: "/ua/partners/:_id()",
    component: () => import("/app/pages/partners/[_id].vue")
  },
  {
    name: "partners-_id___ru",
    path: "/ru/partners/:_id()",
    component: () => import("/app/pages/partners/[_id].vue")
  },
  {
    name: "partners___en",
    path: "/en/partners",
    component: () => import("/app/pages/partners/index.vue")
  },
  {
    name: "partners___ua",
    path: "/ua/partners",
    component: () => import("/app/pages/partners/index.vue")
  },
  {
    name: "partners___ru",
    path: "/ru/partners",
    component: () => import("/app/pages/partners/index.vue")
  },
  {
    name: "payment-success___en",
    path: "/en/payment/success",
    meta: successUvCmUCQewKMeta || {},
    component: () => import("/app/pages/payment/success.vue")
  },
  {
    name: "payment-success___ua",
    path: "/ua/payment/success",
    meta: successUvCmUCQewKMeta || {},
    component: () => import("/app/pages/payment/success.vue")
  },
  {
    name: "payment-success___ru",
    path: "/ru/payment/success",
    meta: successUvCmUCQewKMeta || {},
    component: () => import("/app/pages/payment/success.vue")
  },
  {
    name: "prices___en",
    path: "/en/prices",
    meta: pricesC8xY6SsZYuMeta || {},
    component: () => import("/app/pages/prices.vue")
  },
  {
    name: "prices___ua",
    path: "/ua/prices",
    meta: pricesC8xY6SsZYuMeta || {},
    component: () => import("/app/pages/prices.vue")
  },
  {
    name: "prices___ru",
    path: "/ru/prices",
    meta: pricesC8xY6SsZYuMeta || {},
    component: () => import("/app/pages/prices.vue")
  },
  {
    name: "referal___en",
    path: "/en/referal",
    component: () => import("/app/pages/referal.vue")
  },
  {
    name: "referal___ua",
    path: "/ua/referal",
    component: () => import("/app/pages/referal.vue")
  },
  {
    name: "referal___ru",
    path: "/ru/referal",
    component: () => import("/app/pages/referal.vue")
  }
]