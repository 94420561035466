<template>
<svg viewBox="0 0 79 114" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M70.5302 91.9461H66.4043V88.3584H78.6025V91.9461H74.4767V113.472H70.5302V91.9461Z" fill="white"/>
  <path d="M59.2711 113.832C57.3816 113.832 55.9345 113.293 54.9299 112.217C53.9493 111.141 53.459 109.622 53.459 107.661V94.1709C53.459 92.2096 53.9493 90.6908 54.9299 89.6145C55.9345 88.5382 57.3816 88 59.2711 88C61.1606 88 62.5957 88.5382 63.5763 89.6145C64.5809 90.6908 65.0832 92.2096 65.0832 94.1709V96.8258H61.352V93.9197C61.352 92.3651 60.6942 91.5877 59.3787 91.5877C58.0632 91.5877 57.4055 92.3651 57.4055 93.9197V107.948C57.4055 109.478 58.0632 110.244 59.3787 110.244C60.6942 110.244 61.352 109.478 61.352 107.948V104.109H65.0832V107.661C65.0832 109.622 64.5809 111.141 63.5763 112.217C62.5957 113.293 61.1606 113.832 59.2711 113.832Z" fill="white"/>
  <path d="M40.6777 88.3584H51.4409V91.9461H44.6242V98.5834H50.0417V102.171H44.6242V109.885H51.4409V113.472H40.6777V88.3584Z" fill="white"/>
  <path d="M30.6942 91.9461H26.5684V88.3584H38.7666V91.9461H34.6407V113.472H30.6942V91.9461Z" fill="white"/>
  <path d="M14.5762 88.3584H25.3393V91.9461H18.5227V98.5834H23.9401V102.171H18.5227V109.885H25.3393V113.472H14.5762V88.3584Z" fill="white"/>
  <path d="M0 88.3584H6.02736C7.98864 88.3584 9.45961 88.8846 10.4402 89.937C11.4209 90.9894 11.9112 92.5321 11.9112 94.5651V107.266C11.9112 109.299 11.4209 110.841 10.4402 111.894C9.45961 112.946 7.98864 113.472 6.02736 113.472H0V88.3584ZM5.9556 109.885C6.60139 109.885 7.09171 109.693 7.42657 109.311C7.78534 108.928 7.96472 108.306 7.96472 107.445V94.3858C7.96472 93.5247 7.78534 92.9028 7.42657 92.5201C7.09171 92.1375 6.60139 91.9461 5.9556 91.9461H3.94649V109.885H5.9556Z" fill="white"/>
  <path d="M67.2546 9.37762L63.0176 5.4541L10.736 61.9131L14.973 65.8366L67.2546 9.37762Z" fill="white"/>
  <path d="M23.0839 17.3241C27.0745 12.019 32.7553 8.68565 39.0932 8.7326C42.9899 8.7326 46.6519 10.0471 49.7974 12.3006C52.5674 14.2725 54.9617 16.9955 56.7927 20.2818L62.7081 13.8969C57.2152 5.44623 48.6237 0.000244141 38.9524 0.000244141C29.2811 0.000244141 22.2858 4.46031 16.8398 11.5964L23.0839 17.371V17.3241Z" fill="white"/>
  <path d="M60.7372 35.7282C60.7372 40.7047 59.6104 45.3526 57.6855 49.3432L64.0705 55.2586C67.1691 49.6249 69 42.9113 69 35.6813C69 28.4513 67.7324 23.8504 65.432 18.8269L59.1879 25.5874C60.2208 28.733 60.7841 32.1602 60.7372 35.7752V35.7282Z" fill="white"/>
  <path d="M18.8122 45.7274C17.7793 42.5819 17.2159 39.1547 17.2629 35.5397C17.2629 30.5632 18.3896 25.9153 20.3145 21.9247L13.9296 16.0093C10.831 21.6431 9 28.3566 9 35.5866C9 42.8166 10.2676 47.4176 12.5681 52.441L18.8122 45.6805V45.7274Z" fill="white"/>
  <path d="M54.9154 53.9907C50.9248 59.2958 45.2441 62.6291 38.9061 62.5822C31.5352 62.5822 25.0564 57.9813 21.1127 51.033L15.1973 57.4179C20.6902 65.8686 29.2817 71.3145 38.953 71.3145C48.6243 71.3145 55.6196 66.8545 61.0656 59.7184L54.8215 53.9437L54.9154 53.9907Z" fill="white"/>
</svg>
</template>