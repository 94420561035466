import { useNuxtApp } from '#app';
import type {
  BalanceDto,
  ChangePasswordByTokenDto,
  ResendLinkForVerifPayloadDto,
  SignInPayloadDto,
  SignInResponseDto,
  UserDto,
  UserPayloadDto,
  VerifyAccountPayloadDto,
} from '~/dto';
import type { ApiResponse } from '~/plugins/axios';

export class AuthApi {
  private get $api() {
    const nuxtApp = useNuxtApp();
    return nuxtApp.$api;
  };

  async signUpApi(payload: UserPayloadDto): Promise<ApiResponse<UserDto>> {
    return this.$api.post('/user', payload);
  };

  async resendLinkForVerifApi(payload: ResendLinkForVerifPayloadDto): Promise<ApiResponse<void>> {
    return this.$api.post('/email/verify', payload);
  };

  async verifyAccountApi(payload: VerifyAccountPayloadDto): Promise<ApiResponse<void>> {
    return this.$api.put('/email/verify', payload);
  };

  async signInApi(payload: SignInPayloadDto): Promise<ApiResponse<SignInResponseDto>> {
    return this.$api.post('/session', payload);
  };

  async signOut(): Promise<ApiResponse> {
    return this.$api.del('/session');
  };

  async fetchMeApi(): Promise<ApiResponse<SignInResponseDto>> {
    return this.$api.get('/state');
  };

  async getUserBalance(): Promise<ApiResponse<BalanceDto>> {
    return this.$api.get('/balance');
  };

  async changePassword(userId: UserDto['_id'], password: string): Promise<ApiResponse> {
    return this.$api.patch(`/user/${userId}`, {
      password,
    });
  };

  async resetPassword(email: string): Promise<ApiResponse> {
    return this.$api.post('/password/reset/', {
      email,
    })
  };

  async changePasswordByToken(payload: ChangePasswordByTokenDto): Promise<ApiResponse> {
    return this.$api.put('/password', payload);
  };

  async exceptCurrentSession() {
    return this.$api.del('/session/except/current');
  }

  async freeStarter(payload: VerifyAccountPayloadDto): Promise<ApiResponse<void>> {
    return this.$api.put('/email/verify/and/get/subscription', payload);
  }
};
